import { z } from "zod";

export const appointmentResponse = z.object({
  bookedStaff: z
    .array(
      z.object({
        id: z.string(),
        firstName: z.string(),
        middleName: z.string().optional(),
        lastName: z.string(),
      }),
    )
    .optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  timezone: z.string(),
  status: z.string(),
});

export type AppointmentResponse = z.infer<typeof appointmentResponse>;

export type StaffDetails = {
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  busySlots?: {
    id: string;
    startTime: string;
    endTime: string;
    timezone?: string | null;
  }[];
};

export type TimeOfDay = {
  hour: number;
  minute: number;
};

export type AvailabilityItem = {
  day: number;
  fromTime: TimeOfDay;
  toTime: TimeOfDay;
  enabled?: boolean;
};

export const MAX_DAYS_TO_DISPLAY = 28;
