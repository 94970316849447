import type { Descendant } from "slate";

import { specialChars } from "@smart/itops-utils-basic";

import { isEditorElementType, isEditorText } from "./elements";

type Wrap = "b" | "i";
const wrappers: Record<Wrap, string> = {
  b: "**",
  i: "*",
};
const altWrappers: Record<Wrap, string> = {
  b: "__",
  i: "_",
};

const wrapText = (text: string, key: Wrap, alt?: boolean) => {
  const w = (alt ? altWrappers : wrappers)[key];
  return `${w}${text}${w}`;
};

const previousText = (array: Descendant[], index: number) => {
  const previous = array[index];
  return isEditorText(previous) ? previous : undefined;
};

export const serialize = (
  nodes: Descendant[],
  parentLength = 0,
  delimiter = "\n\n",
): string =>
  nodes
    .map((node, index) => {
      if (isEditorElementType("paragraph")(node)) {
        return serialize(node.children, nodes.length, "");
      }

      if (isEditorElementType("link")(node)) {
        const linkText = serialize(node.children, nodes.length, "");
        return linkText.trim().length > 0 ? `[${linkText}](${node.url})` : "";
      }

      if (isEditorElementType("placeholder")(node)) {
        const { name, isLink, displayText, casing } = node;
        const casingPrefix = casing === "lowercase" ? "_" : "";
        return name
          ? `{${casingPrefix}${name}${displayText ? `|${isLink ? ">" : ""}${displayText}` : ""}}`
          : "";
      }

      if (isEditorText(node)) {
        let text = node.text.trim();
        const charsToEscape = ["*", "#", "-", ">"];

        charsToEscape.forEach((char) => {
          if (text.length && text.startsWith(char))
            text = text.replace(char, `\\${char}`);
        });

        if (text.length && node.italic) text = wrapText(text, "i", node.bold);
        if (text.length && node.bold) text = wrapText(text, "b");

        if (node.text.startsWith(" ")) text = ` ${text}`;
        if (node.text.endsWith(" ") && text !== " ") text = `${text} `;

        const prev = previousText(nodes, index - 1);
        if (
          text.startsWith("*") &&
          (prev?.italic || prev?.bold) &&
          !prev.text.endsWith(" ")
        )
          text = `${specialChars.invisible}${text}`;

        if (nodes.length === 1 && parentLength > 1 && !text) {
          text = specialChars.nbsp;
        }

        return text;
      }

      return "";
    })
    .join(delimiter);
