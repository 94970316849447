import { z } from "zod";

import {
  arrayComparer,
  Operator,
  singleToArrayComparer,
  stringOrNumberComparer,
} from "./comparison";
import { ValidationError } from "./errors";
import { isNotNullOrUndefined } from "./guard";

export type Schema<T> = z.ZodSchema<T, any, any>;

export type Unschema<S> = S extends Schema<infer T> ? T : never;

export type Guard<T> = (data: unknown, action: string) => Promise<T>;

export type Unguard<G> = G extends Guard<infer T> ? T : never;

export const wrapSchema =
  <T>(schema: Schema<T>): Guard<T> =>
  async (data, action) => {
    try {
      return await schema.parseAsync(data);
    } catch (error) {
      throw new ValidationError(error, "ERR-1400", action, data);
    }
  };

export const arrayFilterSchema = <O extends z.ZodObject<any>>(
  objectSchema: O,
) =>
  z.preprocess((input) => {
    const result = z.array(z.any()).parse(input);
    return result.filter((item) => objectSchema.safeParse(item).success);
  }, z.array(objectSchema));

export const jsonParse = (
  input: string | undefined,
  action: string,
): unknown => {
  try {
    return input && JSON.parse(input);
  } catch (error) {
    throw new ValidationError(error, "ERR-1399", action, input);
  }
};

export const jsonParseOrReturn = (input: string | null | undefined) => {
  try {
    return input && JSON.parse(input);
  } catch {
    return input;
  }
};

export const guardList =
  <T>(guard: Guard<T>) =>
  async (list: unknown[], action: string): Promise<T[]> => {
    const items = await Promise.all(
      list.map(async (item) => {
        try {
          return await guard(item, action);
        } catch {
          return undefined;
        }
      }),
    );

    return items.filter(isNotNullOrUndefined);
  };

export const undefinedOrEmptyObject = z.undefined().or(z.object({}).strict());

export type Condition = {
  value: string | number | boolean | string[];
  condition: Operator;
};

export const validateConditionValue = (
  input: string | number | string[] | null | undefined,
  condition: Condition,
): boolean => {
  if (Array.isArray(input)) {
    return arrayComparer(
      input,
      condition.condition,
      condition.value as string[],
    );
  }

  if (typeof input !== "number" && typeof input !== "string") return false;

  if (Array.isArray(condition.value)) {
    return singleToArrayComparer(input, condition.condition, condition.value);
  }

  return stringOrNumberComparer(
    input,
    condition.condition,
    typeof condition.value === "boolean"
      ? condition.value.toString()
      : condition.value,
  );
};
