import { clsx } from "clsx";

const defaultStyles =
  "group flex items-center gap-2 rounded border-neutral-200 border-solid border-[1px] text-neutral-850 bg-neutral-20 px-[1rem] py-[0.65rem]";

const hoverStyles = "hover:bg-white";
const disabledStyles =
  "bg-neutral-50 text-neutral-400 cursor-not-allowed border-neutral-50";
const errorStyles = "border-red-550 shadow-error";
const focusStyles =
  "focus-within:shadow-[0px_0px_0px_4px_rgba(174,211,254,0.6)] focus-within:border-blue-560";

const inputStyles =
  "border-0 outline-none w-full h-10 font-inter text-ui bg-neutral-20 group-hover:enabled:bg-white";
const inputDisabledStyles =
  "disabled:bg-neutral-50 disabled:text-neutral-400 disabled:cursor-not-allowed";

export const getWrapperClassNames = ({
  error,
  disabled,
}: {
  error?: boolean;
  disabled?: boolean;
}) =>
  clsx(
    defaultStyles,
    !disabled && hoverStyles,
    !error && focusStyles,
    disabled && disabledStyles,
    error && errorStyles,
  );

export const inputClassNames = clsx(inputStyles, inputDisabledStyles);
