import styled from "@emotion/styled";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  size as fSize,
  offset,
  shift,
  useFloating,
  useMergeRefs,
} from "@floating-ui/react";
import {
  AutocompleteGroupedOption,
  AutocompleteValue,
  UseAutocompleteProps,
  useAutocomplete,
} from "@mui/base/useAutocomplete";
import { ForwardedRef, forwardRef, ReactNode, RefObject } from "react";

import { Icon, IconDetail } from "@smart/itops-icons-dom";

import { ComboboxTags } from "./combobox-elements";
import { Input, InputKind, InputWrapper } from "./wrapper";
import { FontSizeKey, SchemeColor } from "../../theme";
import { Button } from "../button";

const ComboboxTagWrapper = styled.div`
  display: flex;
  flex: 1 1;
  flex-flow: row wrap;
  align-items: center;
`;

const ComboboxWrapper = styled.ul<{ size?: FontSizeKey }>`
  --background: white;
  background: var(--background);
  border-radius: 0.8rem;
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.05),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  font-size: ${(props) =>
    props.size ? props.theme.fontSize[props.size] : "inherit"};

  overflow: auto;
  z-index: 101;

  list-style: none;
  margin: 0;
  padding: 0.4rem 0.6rem;

  .combo-divider {
    border-bottom: 1px dotted ${(props) => props.theme.scheme.grey.r60};
    margin: 0.4rem 0.8rem;
  }

  li {
    border: 1px solid transparent;
    border-radius: 0.6rem;
    cursor: pointer;
    margin: 0.4rem 0;
    padding: 0.6rem 1.6rem;
    font-size: 0.925em;
    font-weight: 350;

    &.combo-all {
      &:hover:not([aria-selected="true"]) {
        background: ${(props) => props.theme.scheme.grey.r15};
        border-color: ${(props) => props.theme.scheme.blue.r20};
        box-shadow: 0 0 0 1px ${(props) => props.theme.scheme.blue.r10};
      }
    }

    &.combo-group {
      color: ${(props) => props.theme.scheme.grey.r50};
      font-size: 0.8em;
      font-weight: 500;
      padding: 0.4rem 0.2rem;
      pointer-events: none;
    }

    &.combo-focused {
      background: ${(props) => props.theme.scheme.grey.r15};
      border-color: ${(props) => props.theme.scheme.blue.r20};
      box-shadow: 0 0 0 1px ${(props) => props.theme.scheme.blue.r10};
    }

    &.combo-empty {
      opacity: 0.8;
      font-weight: 500;
      font-style: italic;
    }

    &[aria-selected="true"] {
      background: ${(props) => props.theme.scheme.blue.r10};
    }

    &[aria-disabled="true"] {
      cursor: not-allowed;
      opacity: 0.6;
      pointer-events: none;
    }
  }
`;

const isOptionGroup = <V extends any>(
  o: V | AutocompleteGroupedOption<V>,
): o is AutocompleteGroupedOption<V> =>
  typeof o === "object" &&
  (o as any)?.group &&
  Array.isArray((o as any)?.options);

const isMultiple = (v: any, multiple: boolean | undefined): v is any[] =>
  !!multiple && Array.isArray(v);

export type ComboboxProps<
  V,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = {
  size?: FontSizeKey;
  kind?: InputKind;
  icon?: IconDetail & { schemeColor?: SchemeColor };
  error?: boolean;
  className?: string;
  placeholder?: string;
  empty?: string;
  id?: string;
  name?: string;
  title?: string;
  maxItems?: Multiple extends true ? number : undefined;
  selectAll?: Multiple extends true ? boolean : undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  tooltip?: string;
  hideClear?: boolean;
} & UseAutocompleteProps<V, Multiple, DisableClearable, undefined>;

const BaseCombobox = <
  V,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>(
  {
    size,
    kind,
    icon,
    error,
    className,
    placeholder,
    empty,
    id,
    name,
    title,
    disabled,
    autoFocus,
    maxItems,
    selectAll,
    tooltip,
    hideClear,
    ...autocomplete
  }: ComboboxProps<V, Multiple, DisableClearable>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const {
    getRootProps,
    getTagProps,
    getInputProps,
    getClearProps,
    getPopupIndicatorProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    popupOpen,
    dirty,
    value,
    inputValue,
  } = useAutocomplete({
    ...autocomplete,
    id,
    disabled,
    unstable_classNamePrefix: "combo",
  });
  const list = useFloating({
    placement: "bottom",
    open: popupOpen,
    onOpenChange: () => {},
    middleware: [
      shift({ padding: 10 }),
      flip({ fallbackPlacements: ["top"] }),
      offset(2),
      fSize({
        apply({ availableHeight, rects, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, 250)}px`,
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const rootRef = useMergeRefs([ref, setAnchorEl, list.refs.setReference]);
  const optionLabel = (option: V) =>
    autocomplete.getOptionLabel?.(option) || `${option}`;

  const listBoxProps = getListboxProps();
  const floatingRef = useMergeRefs([
    list.refs.setFloating,
    (listBoxProps as { ref: ForwardedRef<HTMLUListElement> }).ref,
  ]);

  const showClear =
    !disabled && dirty && !autocomplete.disableClearable && !hideClear;
  const showSelectAll = autocomplete.multiple && selectAll && !inputValue;

  return (
    <>
      <div {...getRootProps()} ref={rootRef} className={className}>
        <InputWrapper
          kind={kind}
          size={size}
          aria-disabled={disabled}
          iconColor={icon?.schemeColor}
        >
          {icon && <Icon className="input-icon" size={18} {...icon} />}
          <ComboboxTagWrapper>
            {Array.isArray(value) && (
              <ComboboxTags
                value={value}
                maxItems={maxItems}
                optionLabel={optionLabel}
                getTagProps={getTagProps}
              />
            )}
            <Input
              {...getInputProps()}
              id={id}
              placeholder={placeholder}
              disabled={disabled}
              aria-invalid={error}
              aria-errormessage={error ? `${id}-error` : undefined}
              title={tooltip || title}
              aria-label={title}
            />
          </ComboboxTagWrapper>
          {showClear && (
            <Button
              {...getClearProps()}
              kind="borderless"
              icon={{ library: "lucide", name: "X", size: 16 }}
            />
          )}
          <Button
            {...getPopupIndicatorProps()}
            disabled={disabled}
            title={`${popupOpen ? "Close" : "Open"} ${title || name}`}
            className="input-indicator"
            kind="borderless"
            icon={{
              library: "lucide",
              name: "ChevronDown",
              size: 16,
              stroke: 4,
              rotate: popupOpen ? 180 : 0,
            }}
          />
        </InputWrapper>
      </div>
      {popupOpen && (
        <FloatingPortal>
          <ComboboxWrapper
            {...listBoxProps}
            ref={floatingRef}
            style={list.floatingStyles}
            size={size || "base"}
          >
            {showSelectAll && !!autocomplete.options?.length && (
              <>
                <li
                  className="combo-all"
                  {...getOptionProps({
                    option: autocomplete.options[0],
                    index: -1,
                  })}
                  role="option"
                  aria-selected={
                    isMultiple(value, autocomplete.multiple) &&
                    value.length === autocomplete.options.length &&
                    autocomplete.options.every((o) =>
                      value.find((v) =>
                        autocomplete.isOptionEqualToValue
                          ? autocomplete.isOptionEqualToValue(o, v)
                          : o === v,
                      ),
                    )
                  }
                  onKeyUp={() => {}}
                  onClick={(e) => {
                    if (isMultiple(value, autocomplete.multiple)) {
                      const allSelected =
                        value.length === autocomplete.options.length &&
                        autocomplete.options.every((o) =>
                          value.find((v) =>
                            autocomplete.isOptionEqualToValue
                              ? autocomplete.isOptionEqualToValue(o, v)
                              : o === v,
                          ),
                        );

                      if (allSelected) {
                        autocomplete.onChange?.(
                          e,
                          [] as AutocompleteValue<
                            V,
                            Multiple,
                            DisableClearable,
                            undefined
                          >,
                          "removeOption",
                        );
                      } else {
                        autocomplete.onChange?.(
                          e,
                          autocomplete.options as AutocompleteValue<
                            V,
                            Multiple,
                            DisableClearable,
                            undefined
                          >,
                          "selectOption",
                        );
                      }

                      (
                        getInputProps().ref as RefObject<HTMLInputElement>
                      )?.current?.blur();
                    }
                  }}
                >
                  Select All
                </li>
                <div className="combo-divider" />
              </>
            )}
            {groupedOptions.flatMap((option, index) =>
              isOptionGroup(option)
                ? [
                    <li key={option.key} className="combo-group">
                      {option.group}
                    </li>,
                    ...option.options.map((o, i) => (
                      <li
                        {...getOptionProps({
                          option: o,
                          index: option.index + i,
                        })}
                      >
                        {optionLabel(o)}
                      </li>
                    )),
                  ]
                : [
                    <li {...getOptionProps({ option, index })}>
                      {optionLabel(option)}
                    </li>,
                  ],
            )}
            {groupedOptions.length === 0 && (
              <li className="combo-empty">{empty || "No options available"}</li>
            )}
          </ComboboxWrapper>
        </FloatingPortal>
      )}
    </>
  );
};

export const Combobox = forwardRef(BaseCombobox) as <
  V,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
>(
  props: ComboboxProps<V, Multiple, DisableClearable> & {
    ref?: ForwardedRef<HTMLInputElement>;
  },
) => ReactNode;
