import { z } from "zod";

import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";

export const MatterTypeNS = buildNamespace({
  system,
  entity: "MatterType",
} as const);

export const LayoutItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  providerId: z.string(),
  itemId: z.string().optional().nullable(),
});

export const MatterTypeSchema = z.object({
  uri: MatterTypeNS.schema,
  operationId: z.string(),

  id: z.string(),
  name: z.string(),
  category: z.string(),
  location: z.string(),

  representativeOptions: z.array(z.string()).optional(),
  type: z.number(),
  items: z.array(
    LayoutItemSchema.extend({
      subItems: z.array(LayoutItemSchema).optional(),
    }),
  ),
  lastUpdatedAsSec: z.number().optional(),

  deleted: z.boolean().optional(),
  deletedAtSec: z.number().optional(),
});
