import { TimeZone, timeZones } from "./list";
import { partition } from "../array";

type BuildListOption = {
  noDefault?: boolean;
};

const getRegion = (timezone: TimeZone) =>
  timezone.tzCode.split("/").reverse().pop();

export const localTimeZoneCode =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getCurrentTimezoneUtcString = () => {
  const offsetMinutes = new Date().getTimezoneOffset();
  // Invert the offset because getTimezoneOffset returns the difference in minutes from UTC to local time
  const totalMinutes = -offsetMinutes;
  const absMinutes = Math.abs(totalMinutes);
  const hours = Math.floor(absMinutes / 60);
  const minutes = absMinutes % 60;

  return `${totalMinutes >= 0 ? "+" : "-"}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

export const findMatchingTimezone = (timezones: TimeZone[]) =>
  timezones.find((tz) => tz.utc === getCurrentTimezoneUtcString());

export const buildTimeZoneList = (option?: BuildListOption): TimeZone[] => {
  const sortedTimeZones = [...timeZones].sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );
  if (option?.noDefault) return sortedTimeZones;

  const localTzCode = localTimeZoneCode;

  // Attempt to find the exact localTzCode in the time zone list. If not found, fallback to the first time zone with the same UTC offset.
  const local =
    sortedTimeZones.find((item) => item.tzCode === localTzCode) ||
    findMatchingTimezone(sortedTimeZones);
  if (!local) return sortedTimeZones;

  // Move the local time-zone and region to the top of the list
  const [localRegion, otherRegions] = partition(
    sortedTimeZones,
    (item) => getRegion(item) === getRegion(local),
  );
  const [localTimeZone, otherTimeZones] = partition(
    localRegion,
    (item) => item.tzCode === localTzCode,
  );

  return [...localTimeZone, ...otherTimeZones, ...otherRegions];
};
