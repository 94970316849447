// Files contain tailwind class names should have tsx extension for tailwind to scan

import { clsx } from "clsx";

export type ButtonSizes = "default" | "small";

const sizeClasses: Record<ButtonSizes, string> = {
  default: "py-[0.9rem]",
  small: "py-[0.5rem]",
};

type BaseProps = {
  size?: ButtonSizes;
  rounded?: boolean;
};
const base = ({ size, rounded }: BaseProps) =>
  clsx(
    "relative flex justify-center items-center gap-[0.8rem] px-[1.6rem] rounded focus-visible:outline-none disabled:cursor-not-allowed",
    size && sizeClasses[size],
    rounded ? "rounded-large" : "rounded",
  );

export const focusBorder = (rounded?: boolean) =>
  clsx(
    "focus:before:absolute focus:before:inset-[-0.4rem] focus:before:border focus:before:border-blue-560 focus:before:border-solid",
    rounded ? "focus:before:rounded-full" : "focus:before:rounded",
  );

const primaryDefaultBase = (props: BaseProps) =>
  clsx(
    base(props),
    "text-white bg-blue-560 border-solid border border-blue-560",
  );
const primaryDefaultFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-blue-460");
const primaryDefaultHover = "hover:bg-blue-460";
const primaryDefaultActive = "active:bg-blue-660";
const primaryDefaultDisabled =
  "disabled:bg-black/5 disabled:text-neutral-400 disabled:border-none";

const destructiveDefaultBase = (props: BaseProps) =>
  clsx(base(props), "text-white bg-red-550");
const destructiveDefaultFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-red-450");
const destructiveDefaultHover = "hover:bg-red-450";
const destructiveDefaultActive = "active:bg-red-650";
const destructiveDefaultDisabled =
  "disabled:bg-black/5 disabled:text-neutral-400 disabled:border-none";

const primarySubtleBase = (props: BaseProps) =>
  clsx(
    base(props),
    "text-blue-560 bg-white border-solid border border-blue-360",
  );
const primarySubtleFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:border-blue-560 focus:bg-blue-60");
const primarySubtleHover = "hover:border-blue-560 hover:bg-blue-60";
const primarySubtleActive =
  "active:bg-blue-660 active:border-blue-560 active:text-white";
const primarySubtleDisabled =
  "disabled:bg-white disabled:border-neutral-400 disabled:text-neutral-400";

const secondaryDefaultBase = (props: BaseProps) =>
  clsx(
    base(props),
    "text-neutral-850 bg-white border-solid border border-neutral-400",
  );
const secondaryDefaultFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-neutral-50");
const secondaryDefaultHover = "hover:bg-neutral-50";
const secondaryDefaultActive =
  "active:bg-neutral-100 active:border-neutral-100";
const secondaryDefaultDisabled =
  "disabled:bg-white disabled:border-neutral-400 disabled:text-neutral-400";

const secondarySubtleBase = (props: BaseProps) =>
  clsx(base(props), "text-neutral-850");
const secondarySubtleFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-neutral-50");
const secondarySubtleHover = "hover:bg-black/5";
const secondarySubtleActive = "active:bg-black/10";
const secondarySubtleDisabled = "disabled:bg-white disabled:text-neutral-400";

const highlightBase = (props: BaseProps) =>
  clsx(base(props), "text-blue-560 bg-white px-[0.4rem]");
const highlightFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-blue-60");
const highlightHover = "hover:bg-blue-60";
const highlightActive = "active:bg-blue-660 active:text-white";
const highlightDisabled = "disabled:bg-white disabled:text-neutral-400";

const invertedDefaultBase = (props: BaseProps) =>
  clsx(base(props), "text-white border-solid border border-white/40");
const invertedDefaultFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-white/10");
const invertedDefaultHover = "hover:bg-white/10";
const invertedDefaultActive = "active:bg-white/20 active:border-white/0";
const invertedDefaultDisabled =
  "disabled:text-white/40 disabled:bg-white/0 disabled:border-white/40";

const invertedSubtleBase = (props: BaseProps) =>
  clsx(base(props), "text-white border-solid border border-white/0");
const invertedSubtleFocus = (rounded?: boolean) =>
  clsx(focusBorder(rounded), "focus:bg-white/10");
const invertedSubtleHover = "hover:bg-white/10";
const invertedSubtleActive = "active:bg-white/20";
const invertedSubtleDisabled = "disabled:text-white/40 disabled:bg-white/0";

const inlineDefaultBase = (props: BaseProps) =>
  clsx(
    base(props),
    "text-neutral-850 px-[1.2rem] py-[0.12rem] rounded-small border-solid border border-neutral-400",
  );
const inlineDefaultFocus = (rounded?: boolean) =>
  clsx(
    focusBorder(rounded),
    "focus:bg-neutral-50",
    "focus:before:rounded-small",
  );
const inlineDefaultHover = "hover:bg-neutral-50";
const inlineDefaultActive = "active:bg-neutral-100 active:border-neutral-100";
const inlineDefaultDisabled =
  "disabled:text-neutral-400 disabled:bg-white/0 disabled:border-neutral-400";

const inlineSubtleBase = (props: BaseProps) =>
  clsx(inlineDefaultBase(props), "border-white/0");
const inlineSubtleFocus = inlineDefaultFocus;
const inlineSubtleHover = inlineDefaultHover;
const inlineSubtleActive = inlineDefaultActive;
const inlineSubtleDisabled =
  "disabled:text-neutral-400 disabled:bg-white/0 disabled:border-white/0";

export type ButtonVariant =
  | "primaryDefault"
  | "primarySubtle"
  | "destructiveDefault"
  | "secondaryDefault"
  | "secondarySubtle"
  | "highlight"
  | "invertedDefault"
  | "invertedSubtle"
  | "inlineDefault"
  | "inlineSubtle";

const extractActiveClasses = (classes: string) =>
  classes.replace(/active:/g, "");

const activeClasses: Record<ButtonVariant, string> = {
  primaryDefault: extractActiveClasses(primaryDefaultActive),
  primarySubtle: extractActiveClasses(primarySubtleActive),
  destructiveDefault: extractActiveClasses(destructiveDefaultActive),
  secondaryDefault: extractActiveClasses(secondaryDefaultActive),
  secondarySubtle: extractActiveClasses(secondarySubtleActive),
  highlight: extractActiveClasses(highlightActive),
  invertedDefault: extractActiveClasses(invertedDefaultActive),
  invertedSubtle: extractActiveClasses(invertedSubtleActive),
  inlineDefault: extractActiveClasses(inlineDefaultActive),
  inlineSubtle: extractActiveClasses(inlineSubtleActive),
};

export const getVariantClassNames = ({
  loading,
  variant,
  size,
  rounded,
  isPressed,
}: {
  loading: boolean | undefined;
  variant: ButtonVariant;
  size: ButtonSizes;
  rounded?: boolean;
  isPressed?: boolean;
}) => {
  const buttonVariants = {
    primaryDefault: clsx(
      primaryDefaultBase({ size, rounded }),
      !loading && [
        primaryDefaultFocus(rounded),
        primaryDefaultHover,
        primaryDefaultActive,
        primaryDefaultDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    destructiveDefault: clsx(
      destructiveDefaultBase({ size, rounded }),
      !loading && [
        destructiveDefaultFocus(rounded),
        destructiveDefaultHover,
        destructiveDefaultActive,
        destructiveDefaultDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    primarySubtle: clsx(
      primarySubtleBase({ size, rounded }),
      !loading && [
        primarySubtleFocus(rounded),
        primarySubtleHover,
        primarySubtleActive,
        primarySubtleDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    secondaryDefault: clsx(
      secondaryDefaultBase({ size, rounded }),
      sizeClasses[size],
      !loading && [
        secondaryDefaultFocus(rounded),
        secondaryDefaultHover,
        secondaryDefaultActive,
        secondaryDefaultDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    secondarySubtle: clsx(
      secondarySubtleBase({ size, rounded }),
      !loading && [
        secondarySubtleFocus(rounded),
        secondarySubtleHover,
        secondarySubtleActive,
        secondarySubtleDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    highlight: clsx(
      highlightBase({ size, rounded }),
      !loading && [
        highlightFocus(rounded),
        highlightHover,
        highlightActive,
        highlightDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    invertedDefault: clsx(
      invertedDefaultBase({ size, rounded }),
      !loading && [
        invertedDefaultFocus(rounded),
        invertedDefaultHover,
        invertedDefaultActive,
        invertedDefaultDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    invertedSubtle: clsx(
      invertedSubtleBase({ size, rounded }),
      !loading && [
        invertedSubtleFocus(rounded),
        invertedSubtleHover,
        invertedSubtleActive,
        invertedSubtleDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    inlineDefault: clsx(
      inlineDefaultBase({ rounded }),
      !loading && [
        inlineDefaultFocus(rounded),
        inlineDefaultHover,
        inlineDefaultActive,
        inlineDefaultDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
    inlineSubtle: clsx(
      inlineSubtleBase({ rounded }),
      !loading && [
        inlineSubtleFocus(rounded),
        inlineSubtleHover,
        inlineSubtleActive,
        inlineSubtleDisabled,
        isPressed && activeClasses[variant],
      ],
    ),
  };

  return buttonVariants[variant];
};

export const getSingleIconClassNames = (
  variant: ButtonVariant,
  size: ButtonSizes,
) => {
  if (["inlineDefault", "inlineSubtle"].includes(variant))
    return "p-[0.325rem]";
  return size === "default"
    ? "p-[1.2rem] max-h-16 max-w-16"
    : "p-[0.8rem] max-h-[3.2rem] max-w-[3.2rem]";
};
