import { z } from "zod";

import {
  Condition,
  jsonParseOrReturn,
  parseDateString,
  validateConditionValue,
} from "@smart/itops-utils-basic";

import { FieldType } from "./field";
import {
  PhoneNumberResponse,
  isOfPhoneNumberResponseType,
} from "./phone-number-response";

export const linkConditionValue = [
  "equalTo",
  "notEqualTo",
  "greaterThan",
  "greaterThanOrEqualTo",
  "lessThan",
  "lessThanOrEqualTo",
  "in",
  "contain",
] as const;

export const linkConditionLabel: Record<
  (typeof linkConditionValue)[number],
  string
> = {
  equalTo: "equal to",
  notEqualTo: "not equal to",
  greaterThan: "greater than",
  greaterThanOrEqualTo: "greater than or equal to",
  lessThan: "less than",
  lessThanOrEqualTo: "less than or equal to",
  in: "includes any of",
  contain: "includes all of",
};

export const linkConditionSchema = z.enum(linkConditionValue);

export const fieldLinkSchema = z.object({
  fieldUri: z.string(),
  condition: linkConditionSchema,
  value: z.string(),
  hide: z.boolean().optional().nullable(),
});

export type LinkConditionType = (typeof linkConditionValue)[number];

export type FieldLinkType = z.infer<typeof fieldLinkSchema>;

type SingleFieldValueType = string | number | string[] | PhoneNumberResponse;

type ConvertedFieldValueType = string | number | string[];

export const validateConditionLink = ({
  conditionLink,
  fieldValue,
  fieldType,
  group,
  affectingGroupUri,
  index,
}: {
  conditionLink: FieldLinkType;
  fieldValue: SingleFieldValueType | SingleFieldValueType[];
  fieldType: FieldType;
  group:
    | {
        uri: string | undefined | null;
        repeatable?: boolean | null;
      }
    | undefined;
  affectingGroupUri?: string | null;
  index?: number;
}): boolean => {
  const isInSameGroup = group?.uri && group.uri === affectingGroupUri;

  const getConvertedValue = (
    valueIndex?: number,
  ): ConvertedFieldValueType | null => {
    // If the field belongs to a group, it is an array of values, get the value at the index.
    const selectedValue = (
      group?.uri && Array.isArray(fieldValue)
        ? fieldValue[valueIndex || 0]
        : fieldValue
    ) as SingleFieldValueType;

    if (fieldType === "date")
      return parseDateString(selectedValue as string).getTime();
    if (isOfPhoneNumberResponseType(selectedValue))
      return selectedValue.formattedNumber;
    return selectedValue;
  };

  const getConditionValue = () => {
    const value = jsonParseOrReturn(conditionLink.value);
    if (fieldType === "date") return parseDateString(value).getTime();
    return value;
  };

  const condition: Condition = {
    value: getConditionValue(),
    condition: conditionLink.condition,
  };

  // check if condition is met
  let isConditionMet;
  // If it's a repeatable field, check if any values meets the condition
  if (group?.repeatable && !isInSameGroup && Array.isArray(fieldValue)) {
    isConditionMet = fieldValue.some((_, valueIndex) =>
      validateConditionValue(getConvertedValue(valueIndex), condition),
    );
  } else {
    isConditionMet = validateConditionValue(
      getConvertedValue(index),
      condition,
    );
  }

  // if conditionLink.hide is true, it's a 'hiding condition': hide the question if the condition is met (invert the result)
  // if conditionLink.hide is false, it's a 'showing condition': show the question if the condition is met (return the result as is)
  return conditionLink.hide ? !isConditionMet : isConditionMet;
};
