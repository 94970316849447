import { clsx } from "clsx";

import { Displayer } from "@smart/itops-editor-dom";

import { Button } from "../button";
import { Icon, IconName } from "../icon";

export type ModalHeaderProps = {
  icon?: IconName;
  iconBgColor?: string;
  text?: string;
  onClose?: () => void;
};

export const ModalHeader = ({
  icon,
  iconBgColor = "bg-orange-200",
  text,
  onClose,
}: ModalHeaderProps) => (
  <div className="flex justify-between items-center gap-medium h-[6.4rem] px-[2.4rem] py-[1.2rem]">
    {icon && (
      <div
        className={clsx(
          "rounded w-[4rem] h-[4rem] flex justify-center items-center",
          iconBgColor,
        )}
      >
        <Icon
          className={
            icon === "archie" ? "w-[4rem] h-[4rem]" : "w-[2.2rem] h-[2.2rem]"
          }
          name={icon}
        />
      </div>
    )}
    {text && (
      <div className="text-h3 font-semibold font-poppins flex-1  [&>h4]:truncate overflow-hidden">
        <Displayer value={text} paragraphTag="h4" />
      </div>
    )}
    {onClose && (
      <Button
        leftIcon="solidXmarkLarge"
        aria-label="Close Modal - X"
        variant="secondarySubtle"
        onClick={() => onClose()}
      />
    )}
  </div>
);
