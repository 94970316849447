import { clsx } from "clsx";

export const getGroupClassNames = ({
  align,
  direction,
}: {
  align?: "start" | "end" | "center";
  direction?: "row" | "column";
}) => {
  const baseStyles = "flex gap-2 justify-center flex-col";
  return clsx(
    baseStyles,
    align === "start" && "justify-start",
    align === "end" && "justify-end",
    direction === "row" && "flex-row",
  );
};

export const getRadioCardClassNames = ({
  disabled,
  readOnly,
  box,
  error,
}: {
  disabled?: boolean;
  readOnly?: boolean;
  box?: boolean;
  error?: boolean;
}) => {
  const baseStyles = "group relative flex flex-row my-0";
  const boxStyles = "border border-neutral-200 rounded-card p-4";
  const hoverStyles = "hover:bg-neutral-50";
  const focusStyles =
    "focus-within:bg-neutral-50 focus-within:border-neutral-200 focus-within:outline-blue-560 focus-within:outline-1 focus-within:outline focus-within:outline-offset-1";
  const activeStyles = "active:bg-blue-50";
  const errorStyles =
    "outline-red-550 outline-1 outline outline-offset-1 bg-red-50";

  return clsx(
    baseStyles,
    box && boxStyles,
    !disabled && activeStyles,
    !disabled && !error && box && focusStyles,
    box && !disabled && hoverStyles,
    box && error && errorStyles,
    readOnly && "cursor-not-allowed",
  );
};

export const getRadioButtonClassNames = ({
  checked,
  disabled,
  error,
}: {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
}) => {
  const baseStyles =
    "relative w-8 h-8 border border-solid border-neutral-200 bg-neutral-20 rounded-full my-1 mr-[0.3rem]";
  const baseCheckedStyles =
    "bg-white border-blue-560 border-[0.6rem] group-focus-within:border-blue-460";
  const baseHoverStyles = "hover:bg-white group-hover:bg-white";
  const baseActiveStyles =
    "group-active:bg-blue-160 group-active:border-blue-160 group-active:outline-none";

  const focusStyles =
    "group-focus-within:outline-blue-560 group-focus-within:outline-1 group-focus-within:outline group-focus-within:outline-offset-1 group-focus-within:bg-white";
  const disabledStyles = "bg-[#0000001A] border-none";
  const checkedDisabledStyles =
    "bg-neutral-50 border-neutral-400 border-[0.6rem]";
  const errorStyles =
    "outline-red-550 outline-1 outline outline-offset-1 bg-white";

  const checkedStyles = clsx(baseCheckedStyles, error && "border-blue-460");

  const hoverStyles = clsx(
    baseHoverStyles,
    checked && "group-hover:border-blue-460",
  );

  const activeStyles = clsx(
    baseActiveStyles,
    checked &&
      "group-active:bg-white group-active:border-blue-660 group-active:border-[0.6rem]",
  );

  return clsx(
    baseStyles,
    !disabled && activeStyles,
    !disabled && !error && focusStyles,
    !disabled && !error && hoverStyles,
    checked && !disabled && checkedStyles,
    disabled && !checked && disabledStyles,
    checked && disabled && checkedDisabledStyles,
    error && errorStyles,
  );
};

export const getLabelsClassNames = ({
  disabled,
  box,
}: {
  disabled?: boolean;
  box?: boolean;
}) => {
  const groupStyles = "mx-2 gap-small flex items-left justify-center";

  return {
    labelGroupClassNames: clsx(groupStyles, disabled && "text-neutral-400"),
    titleClassNames: clsx(box && "font-semibold"),
  };
};
